<script>
  import PayoutNav from '@/components/payouts/PayoutNav';
  import PageDataRoot from '@/components/shared_components/page_data/PageDataRoot';

  export default {
    components: {
      'sidebar-nav': PayoutNav
    },
    extends: PageDataRoot,
    computed: {
      showMobNav() { return this.$route.name == 'ServerPayouts'; }
    }
  };
</script>
